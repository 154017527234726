@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.w-90 {
  width: 90%;
}

.mw-100p {
  max-width: 100px;
}

.mw-1000p {
  max-width: 1000px;
}

.pre-wrap {
  white-space: pre-wrap;
}

// modal padding
.modal-extra-padding .modal-content {
  padding: 1.75rem !important;
}

.shadow-xl,
.modal-shadow-xl .modal-content {
  box-shadow: 0 2rem 3rem 0 rgba(0, 0, 0, 0.175), 0 -2rem 3rem 0 rgba(0, 0, 0, 0.175) !important;
}

.shadow-lgr {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19) !important;
}

.rounded-xl {
  border-radius: 10px !important;
}

.rounded-lgr {
  border-radius: 0.5rem !important;
}

.rounded-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border.border-dashed {
  border-style: dashed !important;
}

.height-restrict {
  max-height: 250px;
  overflow-y: auto;
}

.taller-height-restrict {
  max-height: 350px;
  overflow-y: auto;
}

.flex-basis-0,
.flex-basis-lg-0 {
  flex-basis: 0 !important;
}

.overflow-hidden-lg {
  overflow: hidden;
}

.offcanvas-wrapper > * {
  transition: all 0.6s ease-in-out;
}

.offcanvas-collapse {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  visibility: hidden;
  transition-timing-function: ease-in-out;
  transition-duration: 0.6s;
  transition-property: left, visibility;
  &.show {
    display: flex;
    visibility: visible;
    & .collapse-content {
      z-index: $zindex-modal;
    }
  }
  &.offcanvas-collapse-fullscreen {
    top: $header-height;
    bottom: 0;
    left: 90%;
    width: 90%;

    &.show {
      left: 10%;
    }
  }
  &.offcanvas-collapse-fit {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
  }
}

@include media-breakpoint-down(lg) {
  .flex-basis-lg-0 {
    flex-basis: auto !important;
  }
  .overflow-hidden-lg {
    overflow: inherit;
  }
  // special class for mobile only fullscreen drawer
  .offcanvas-collapse-lg {
    position: fixed;
    top: $header-height;
    bottom: 0;
    left: 90%;
    width: 90%;
    overflow-y: auto;
    visibility: hidden;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;

    &.show {
      display: flex;
      left: 10%;
      visibility: visible;
      & .collapse-content {
        z-index: $zindex-modal;
      }
    }
  }
}

.full-height {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.grabbable {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.crosshairable {
  cursor: crosshair !important;
}

.border-grey {
  border-color: $border-grey;
}

@include media-breakpoint-up(lg) {
  .full-height {
    height: calc(100vh - #{$from-top-crumbs});
    &.no-crumbs {
      height: calc(100vh - #{$from-top-no-crumbs});
    }
    overflow: hidden;
  }

  .scrolling-sidebar {
    overflow-y: scroll;
    height: 100%;
  }

  .scrolling-main {
    overflow-y: auto;
    height: 100%;
  }
}

.cp-1 {
  background-color: $cp-1;
}
.cp-2 {
  background-color: $cp-2;
}
.cp-3 {
  background-color: $cp-3;
}
.cp-4 {
  background-color: $cp-4;
}
.cp-5 {
  background-color: $cp-5;
}

.teams-cp-1 {
  background-color: $teams-cp-1 !important;
}
.teams-cp-2 {
  background-color: $teams-cp-2 !important;
}
.teams-cp-3 {
  background-color: $teams-cp-3 !important;
}
.teams-cp-4 {
  background-color: $teams-cp-4 !important;
}
.teams-cp-5 {
  background-color: $teams-cp-5 !important;
}
.teams-cp-6 {
  background-color: $teams-cp-6 !important;
}
.teams-cp-7 {
  background-color: $teams-cp-7 !important;
}
.teams-cp-8 {
  background-color: $teams-cp-8 !important;
}

.btn.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 4px 1px;
  font-size: $btn-font-size;
  line-height: 1;
  border-radius: 15px;
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: $btn-font-size-lg;
    line-height: 1;
    border-radius: 25px;
  }
}

/* TODO: Clean this up to use size-based widths (sm, lg, xl) */
$border-width-custom-1: 1px !default;
$border-width-custom-2: 2px !default;
$border-width-custom-3: 3px !default;
$border-width-custom-4: 4px !default;
$border-width-custom-5: 5px !default;
$border-width-custom-6: 6px !default;
$border-width-custom-7: 7px !default;
$border-width-custom-8: 8px !default;

$border-width-customs: (
  '1': $border-width-custom-1,
  '2': $border-width-custom-2,
  '3': $border-width-custom-3,
  '4': $border-width-custom-4,
  '5': $border-width-custom-5,
  '6': $border-width-custom-6,
  '7': $border-width-custom-7,
  '8': $border-width-custom-8,
);

@each $name, $size in $border-width-customs {
  @each $var in '', 'top-', 'right-', 'bottom-', 'left-' {
    .border-#{$var}#{$name} {
      border-#{$var}width: $size !important;
      border-#{$var}style: solid !important;
    }
  }
}

.mr-6 {
  margin-right: 3.5rem !important;
}

.opacity-75 {
  opacity: 0.75;
}
.opacity-45 {
  opacity: 0.45;
}

.width-1 {
  width: 1px;
}

.btn-gradient {
  background-image: linear-gradient(to right, $brand-light-blue 51%, $brand-green 100%);
  color: $alt-barely-blue;
  border: none;
}

.btn.btn-cancel {
  @include button-outline-variant($alt-medium-gray, $alt-dark-gray, darken($alt-white, 7.5%));
  @include border-radius($btn-border-radius-lg, 0);
  padding-left: $btn-padding-x-lg;
  padding-right: $btn-padding-x-lg;

  &::before {
    content: '\f05e';
    font-family: FontAwesome;
    color: $alt-medium-gray;
    margin-right: map-get($spacers, 2);
    margin-left: map-get($spacers, 2);

    &:hover {
      color: $alt-dark-gray;
    }
  }
}

.table.table-fixed {
  table-layout: fixed;
}

.rotated-180 {
  transform: rotate(180deg);
}

.rotated-270 {
  transform: rotate(270deg);
}

@each $size, $length in $spacers {
  .gap-#{$size} {
    gap: $length !important;
  }
}

.above-modal {
  z-index: $zindex-modal + 1 !important;
}

/*
 * Expand on Bootstrap sizes
 *
 * Support for multiples of 10 from 10% -> 100%
 * Support for 2^x from 2,4,8,16...
 */

@for $i from 1 through 10 {
  $pow-val: $i * $i;
  $mult-ten: $i * 10;

  .w-#{$pow-val}vw {
    width: #{$pow-val}vw !important;
  }
  .w-#{$mult-ten}vw {
    width: #{$mult-ten}vw !important;
  }

  .mw-#{$pow-val}vw {
    max-width: #{$pow-val}vw !important;
  }
  .mw-#{$mult-ten}vw {
    max-width: #{$mult-ten}vw !important;
  }

  .minw-#{$pow-val}vw {
    min-width: #{$pow-val}vw !important;
  }
  .minw-#{$mult-ten}vw {
    min-width: #{$mult-ten}vw !important;
  }

  .maxw-#{$pow-val}vw {
    max-width: #{$pow-val}vw !important;
  }
  .maxw-#{$mult-ten}vw {
    max-width: #{$mult-ten}vw !important;
  }

  .h-#{$pow-val}vw {
    height: #{$pow-val}vh !important;
  }
  .h-#{$mult-ten}vh {
    height: #{$mult-ten}vh !important;
  }

  .mh-#{$pow-val}vh {
    max-height: #{$pow-val}vh !important;
  }
  .mh-#{$mult-ten}vh {
    max-height: #{$mult-ten}vh !important;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.bg-gray {
  background-color: $background-grey;
}

.new-position-context {
  transform: translate(0);
}
