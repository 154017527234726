$fa-font-path: '../node_modules/font-awesome/fonts';

// ---------- Colors ----------
$brand-dark-blue: #0c243f;
$brand-light-blue: #00a3e0;
$brand-green: #00af66;
$brand-gray: #eaeaea;
$brand-dark-gray: #53565a;

$alt-light-blue: #00a0d6;
$alt-dark-blue: #288cbf;
$alt-steel-blue: #1f6e90;
$alt-barely-blue: #d9eef7;
$alt-baby-blue: #9edbf0;
$alt-green: #3bc489;
$alt-light-green: #00ae66;
$alt-lightest-gray: #dfedf9;
$alt-lighter-gray: #dee2e6;
$alt-light-gray: #bebebe;
$alt-medium-gray: #90979d;
$alt-dark-gray: #777a85;
$alt-white: #fcfcfc;
$alt-red: #e05353;
$alt-orange: #e78b2d;
$alt-purple: #8e2784;
$alt-yellow: #ffe869;
$alt-pink: #e14482;

$placeholder-gray: #6c757d;

$brand-colors: (
  'alt-steel-blue': $alt-steel-blue,
  'brand-dark-blue': $brand-dark-blue,
  'brand-light-blue': $brand-light-blue,
  'brand-green': $brand-green,
  'brand-gray': $brand-gray,
  'brand-dark-gray': $brand-dark-gray,
  'alt-light-blue': $alt-light-blue,
  'alt-dark-blue': $alt-dark-blue,
  'alt-barely-blue': $alt-barely-blue,
  'alt-baby-blue': $alt-baby-blue,
  'alt-green': $alt-green,
  'alt-light-green': $alt-light-green,
  'alt-lightest-gray': $alt-lightest-gray,
  'alt-lighter-gray': $alt-lighter-gray,
  'alt-light-gray': $alt-light-gray,
  'alt-medium-gray': $alt-medium-gray,
  'alt-dark-gray': $alt-dark-gray,
  'alt-white': $alt-white,
  'alt-red': $alt-red,
  'alt-purple': $alt-purple,
  'alt-yellow': $alt-yellow,
  'alt-orange': $alt-orange,
  'alt-pink': $alt-pink,
);
$opacity: (
  'low': 0.1,
  'med': 0.2,
  'high': 0.4,
);

$border-grey: #6c757d80;
$border-light-grey: #ced4da;
$background-grey: #f3f5f7;

$cp-1: rgba(54, 162, 235, 0.4);
$cp-2: rgba(75, 192, 192, 0.4);
$cp-3: rgba(255, 99, 132, 0.4);
$cp-4: rgba(255, 205, 86, 0.4);
$cp-5: rgba(201, 203, 207, 0.4);

$teams-cp-1: rgba(59, 196, 137, map-get($opacity, 'high'));
$teams-cp-2: rgba(0, 160, 214, map-get($opacity, 'high'));
$teams-cp-3: rgba(119, 122, 133, map-get($opacity, 'high'));
$teams-cp-4: rgba(231, 139, 45, map-get($opacity, 'high'));
$teams-cp-5: rgba(31, 110, 144, map-get($opacity, 'high'));
$teams-cp-6: rgba(142, 39, 132, map-get($opacity, 'high'));
$teams-cp-7: rgba(225, 68, 130, map-get($opacity, 'high'));
$teams-cp-8: rgba(238, 218, 59, map-get($opacity, 'high'));

$black-50: rgba(0, 0, 0, 0.5);

// ---------- Heights ----------------
$header-height: 68px;
$from-top-no-crumbs: 112px;
$from-top-crumbs: 108px; // crumbs = 40px

// ---------- Widths ----------
$client-logo-container-width: 170px;

// ---------- Bootstrap overrides ----------
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1.5rem;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.75rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 2.25rem;

$font-size-base: 1.6rem;
$input-btn-line-height: 1.8;
$input-btn-focus-box-shadow: none;
$input-focus-border-color: $alt-steel-blue;
$link-color: $brand-light-blue;
$breadcrumb-bg: rgba(0, 0, 0, 0);

$background-blue: rgb(235, 242, 245);

$form-check-input-gutter: 1.75rem;
$form-check-input-margin-y: 0.5rem;

$custom-control-indicator-size: 2rem !default;

$table-accent-bg: rgba($alt-barely-blue, map-get($opacity, 'med'));

$embed-responsive-aspect-ratios: ((3 4), (9 16), (9 21)) !default;

// ---------- Bounding Boxes ----------
$handle-border-width: 0.3rem;
$handle-radius: 2.5rem;
$bounding-border-width: 0.7rem;
$handle-hv-offset: calc(#{$handle-radius} / 2);
$offset: calc(calc(#{$handle-radius} + #{$bounding-border-width}) / 2);
$corner-offset: calc(calc(#{$handle-radius} / 2) - calc(#{$bounding-border-width} / 2));
$negative-offset: calc(#{$offset} * -1);
