@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'variables';

.in-input-icon {
  margin-left: -40px;
  z-index: 999;
  width: 40px;
}

.in-input-prepend-icon {
  margin-right: -40px;
  z-index: 1000;
  width: 40px;
}

.btn.btn-link,
.btn.btn-link:hover,
.btn.btn-link:active {
  text-decoration: none;
}

.form-control.task-input,
.form-control:disabled.task-input,
.form-control[readonly].task-input {
  background: $alt-white;
  /* border: 1px $alt-steel-blue solid; */
  border: none;
  border-radius: 4px;
  color: $alt-light-blue;
  font-weight: bold;
  text-align: right;
}

.task-input-label {
  color: $alt-light-blue;
  font-weight: bold;
  padding-left: 2em;
}

.previous-action-links {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
}

.accordion > .previous-task.card.open > .card-header {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none !important;
  border-bottom: 1px solid $brand-gray;
}

li.previous-task,
.accordion > .previous-task.card > .card-header {
  border-radius: 10px;
  background-color: $alt-white;
  padding: 0.625rem 1.25rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
  border: 0.5px solid $brand-gray;

  .link,
  .link:hover {
    color: $alt-steel-blue;
    font-weight: 700;
    margin-left: 0.5em;
    text-decoration: none;
  }

  .circle {
    color: $brand-green;
  }

  .item {
    color: $alt-steel-blue;
    font-weight: 700;
    margin-right: 0.5em;
  }

  &.completed,
  &.completed .link {
    color: var(--grey-500);
  }

  &.picked {
    background-color: var(--grey-200);
  }
}

.date {
  font-style: italic;
  padding-left: 3em;
  font-size: 0.8em;
}

.with-arrow:after {
  display: block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f054';
  transform: rotate(0deg);
  transition: all linear 0.25s;
}
.with-arrow.rotated:after {
  transform: rotate(90deg) !important;
}

.detail-card {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  width: 100%;
  //color: $alt-steel-blue;
  word-wrap: normal;
  & > .card-header {
    background-color: $alt-dark-blue;
    color: white;
  }
  & > .card-body {
    padding: 1.5em 3em 1.5em 3em;
  }
}

.card.detail-card-new {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.5rem;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  & > .card-header {
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1em 1.5em 1em 1.5em;
  }
  & > .card-body {
    background-color: white;
    padding: 1em 1.5em 1em 1.5em;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &.selected {
    border: 2px solid $alt-steel-blue;
    border-radius: 0.5rem;
    & > .card-header {
      background-color: $alt-white;
    }
    & > .card-body {
      background-color: $alt-white;
    }
  }

  .right-icon {
    top: 0;
    right: 0;
    height: 100%;

    &.see-thru {
      opacity: 0.2 !important;
    }
  }
}

.card.internal-card {
  background-color: transparent !important;
  border: 0;
  & > .card-header {
    background: rgba(0, 0, 0, 0.1);
  }
  & > .card-body {
    background: rgba(0, 0, 0, 0.1);
  }
}

.table.table-janus-new {
  border-collapse: collapse;
  th {
    background-color: $alt-dark-blue;
    color: white;
    text-align: center;
    border: 0;
  }
  td {
    color: $alt-dark-gray;
  }
}

.table.table-janus {
  border-collapse: collapse;
  th {
    background-color: $alt-dark-blue;
    color: white;
    text-align: center;
    border: 0;
  }
  td {
    color: $alt-dark-gray;
  }
}
th.sortable {
  cursor: pointer;

  &.asc:after {
    content: '\f0d8';
  }
  &.desc:after {
    content: '\f0d7';
  }
  &.desc:after,
  &.asc:after {
    font-family: FontAwesome;
    font-size: 1.5rem;
    padding-left: 5px;
    vertical-align: middle;
  }
}

.table.table-details {
  color: $alt-light-blue;
  background-color: $alt-white;
}

.pagination {
  margin-bottom: 0px;

  .page-item {
    display: none;
  }
  .page-item:nth-child(1) {
    display: block;
    margin-right: 2rem;
  }
  .page-item:last-child {
    display: block;
    margin-left: 2rem;
  }
  // TODO
}

// datepicker
.ngb-dp-month {
  width: 100% !important;
}
.ngb-dp-weekdays,
.ngb-dp-week {
  justify-content: space-evenly;
}

// don't style anchor tags
a.nostyle:link,
a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

.badge-alt-steel-blue {
  background-color: $alt-steel-blue;
  color: white;
  font-size: $font-size-base;

  & > .close {
    float: none !important;
    color: white !important;
    font-size: $font-size-base;
  }
}

.badge.badge-light {
  background-color: $alt-light-gray !important;
}

.nav-pills.muted > .nav-link {
  color: silver;
  &.active {
    background-color: $alt-white;
    color: $alt-steel-blue;
  }
}

.carousel-control-next,
.carousel-control-prev {
  background-color: $brand-gray;
  opacity: 0.05;
  max-width: 100px;
}

// hackity hack - empty dd elements shrink in height so, make sure they have
// an empty space in them ALWAYS!
dd:after {
  content: '\200b';
}

.hover-bg:hover {
  background-color: rgba($alt-dark-blue, map-get($opacity, 'low'));
}

.dropdown-menu {
  max-height: 30vh;
  overflow-y: auto;
}

label.required:after {
  content: '*';
  font-size: 1.5em;
  vertical-align: middle;
  color: $danger;
  padding-left: 0.125em;
}

.card.login-wrapper {
  background-color: rgba(252, 252, 252, 0.8);
  border-radius: 0.3rem;
  // this is dirty
  input {
    color: $alt-dark-gray;
    font-family: FontAwesome, 'Noto Sans JP';
    // font-weight: 700;
    background-color: $alt-white;
    height: 58px;
    border: none;
    text-align: center;
    border-radius: 0.3em;
  }
}

// style popovers throughout the app
.popover {
  background-color: $alt-barely-blue !important;
  .arrow:after {
    border-left-color: $alt-barely-blue !important;
  }
}

.accordion > .card.styled-accordion {
  &:not(:last-of-type) {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $alt-light-gray;
  }

  .card-header {
    padding: 0.25rem;
    background-color: rgb($alt-light-gray, 0.1);
    .btn {
      width: 100%;
      text-align: left;
      color: $alt-dark-gray;
    }
  }
  &:last-of-type {
    border: 0;
  }
  .card-body {
    padding: 0;
  }
}

.crosshairs {
  cursor: crosshair;
}

.list-group.list-group-hover .list-group-item {
  &:hover {
    background-color: $primary;
    color: $alt-white;
  }
}

.list-group {
  &.styled-list-group {
    & .list-group-item:hover,
    & .list-group-item.active {
      color: $brand-light-blue !important;
      background-color: $alt-barely-blue !important;
    }
  }
}

.janus-font {
  font-family: 'janus';
}

.truncate-beginning-wrapper {
  direction: rtl;
  .truncate-beginning {
    unicode-bidi: plaintext;
  }
}

.cdkDragShowNothing {
  height: 0px;
}

.dropdown-toggle {
  .no-dropdown-arrow::after {
    display: none !important;
  }
  body &::after {
    border: 0;
    content: '\f107';
    font-family: FontAwesome;
    line-height: 0;
    transition: all 0.35s ease;
    vertical-align: middle;
  }

  body .show > &::after {
    transform: rotate(-180deg);
  }
}
.fs-10 {
  font-size: 1rem;
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-13 {
  font-size: 1.3rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.logo-bg {
  clip-path: polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 0% 100%);
  width: $client-logo-container-width;
  height: $header-height;
  background-color: $alt-white;

  & > .img-fluid {
    max-height: 40px;
    max-width: 127px;
  }
}

.modal-content {
  max-height: calc(100vh - 2rem);

  .modal-body {
    overflow-y: auto;
    height: 100%;
  }
}

.dropdown-item--secondary {
  color: $brand-light-blue !important;
  text-align: right !important;
}

.show .rotate-on-open::after {
  transform: rotate(180deg);
}

// ------------- Bounding Box Handles -------------
@mixin drag-handle {
  border-radius: 50%;
  height: $handle-radius;
  width: $handle-radius;
  z-index: 10;
}

.drag-handle-horizontal {
  left: calc(50% - #{$handle-hv-offset});
  cursor: ns-resize;
}

.drag-handle-vertical {
  top: calc(50% - #{$handle-hv-offset});
  cursor: ew-resize;
}
.drag-handle-top-left {
  top: $negative-offset;
  left: $negative-offset;
  cursor: nwse-resize;
}

.drag-handle-top-right {
  left: calc(100% - #{$corner-offset});
  top: $negative-offset;
  cursor: nesw-resize;
}

.drag-handle-bottom-left {
  top: calc(100% - #{$corner-offset});
  left: $negative-offset;
  cursor: nesw-resize;
}

.drag-handle-bottom-right {
  left: calc(100% - #{$corner-offset});
  top: calc(100% - #{$corner-offset});
  cursor: nwse-resize;
}

.drag-handle-top {
  top: $negative-offset;
  right: $offset;
}

.drag-handle-right {
  right: $negative-offset;
}

.drag-handle-bottom {
  bottom: $negative-offset;
}

.drag-handle-left {
  left: $negative-offset;
}

.offset-container {
  height: 0px;
  position: relative;
  top: 0px;
  right: 0px;

  &__child {
    position: relative;
    top: -40px;
    right: 0px;
    margin-left: auto;
    width: 50px;
  }
}

// Use this to indicate that a wrapper component has no display footprint save for its inner elements
.no-wrapper-box {
  display: contents;
}

// This is specifically to un-bold the selected filter options in VisionX Document Manager
ngb-highlight {
  > span.ngb-highlight {
    font-weight: 400 !important;
  }
}

.sticky-top {
  z-index: 1000;
}

.w-33rem {
  width: 33rem;
}

.z-index-zero {
  z-index: 0;
}

.cursor-text {
  cursor: text;
}

.cursor-pointer {
  cursor: pointer;
}
