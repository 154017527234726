@import 'node_modules/@janushealthinc/janus-ui-kit/styles/index.scss'; // JUI styles come before everything else
@import 'styles/variables'; // includes Bootstrap overrides!
@import 'styles/helpers';
@import 'styles/common';
@import 'node_modules/@janushealthinc/janus-ui-kit/styles/index.scss';

// Colors
$theme-colors: (
  'primary': $alt-dark-blue,
  'secondary': $brand-dark-gray,
  'danger': $alt-red,
  'success': $alt-green,
  'info': $brand-light-blue,
  'light': $alt-white,
  'dark': $brand-dark-blue,
  'grey-200': $gray-200,
  'grey-300': $gray-300,
  'grey-500': $gray-500,
  'grey-700': $gray-700,
  'alt-barely-yellow': scale-color($alt-yellow, $lightness: 80%),
  'alt-barely-blue-low': scale-color($alt-barely-blue, $lightness: 60%),
  'alt-orange-light': scale-color($alt-orange, $lightness: 40%),
  'alt-orange-lighter': scale-color($alt-orange, $lightness: 20%),
  'alt-green-light': scale-color($alt-green, $lightness: 40%),
  'alt-red-light': scale-color($alt-red, $lightness: 40%),
  'alt-red-lighter': scale-color($alt-red, $lightness: 20%),
  'alt-purple-light': scale-color($alt-purple, $lightness: 40%),
  'alt-barely-purple': scale-color($alt-purple, $lightness: 75%),
  'alt-barely-purple-low': scale-color($alt-purple, $lightness: 90%),
  'alt-orange-high': scale-color($alt-orange, $lightness: 20%),
  'alt-orange-med': scale-color($alt-orange, $lightness: 60%),
  'alt-orange-low': scale-color($alt-orange, $lightness: 80%),
  'alt-green-high': scale-color($alt-green, $lightness: 20%),
  'alt-green-med': scale-color($alt-green, $lightness: 60%),
  'alt-green-low': scale-color($alt-green, $lightness: 80%),
  'alt-red-high': scale-color($alt-red, $lightness: 20%),
  'alt-red-med': scale-color($alt-red, $lightness: 60%),
  'alt-red-low': scale-color($alt-red, $lightness: 80%),
  'alt-purple-high': scale-color($alt-purple, $lightness: 20%),
  'alt-purple-med': scale-color($alt-purple, $lightness: 60%),
  'alt-purple-low': scale-color($alt-purple, $lightness: 80%),
);

$theme-colors: map-merge($theme-colors, $brand-colors);

// opacity 10, 20, 40 for bg- classes

@each $name, $value in $brand-colors {
  @each $label, $op in $opacity {
    .bg-#{$name}-#{$label} {
      background-color: rgba($value, $op) !important;
    }
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1570px,
);

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/noto-sans-jp-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/noto-sans-jp-v28-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/noto-sans-jp-v28-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/NotoSansJP-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/NotoSansJP-Bold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP-Bold.woff') format('woff');
}

@font-face {
  font-family: 'janus';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/janus.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/janus.woff') format('woff');
}

// Explicitly set flex defaults (for the benefit of IE)
* {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

// ugh - don't do this!  ( style elements )
a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
  cursor: pointer;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
}
html {
  font-size: 10px;
}
body {
  overflow-x: hidden;
}

main {
  flex-grow: 1;
  height: 100%;
}
app-root > app-teleport-feedback,
app-root > app-login-page,
app-root > app-reset-page,
app-root > app-new-password-page {
  background-color: $brand-dark-blue;
}
app-root > app-teleport-error {
  background-color: $brand-dark-blue;
  background-image: radial-gradient(rgba(12, 35, 64, 0.92), rgba(12, 35, 64, 0.45)),
    url('/assets/theme/telibot_error_bg.svg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
app-root,
app-root > app-logged-in-page {
  background-color: $brand-dark-blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > * {
    display: flex;
    flex-direction: column;
    //width: 100%;
  }
  router-outlet {
    flex: 0 0 0;
    height: 0;
  }
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top {
  margin-bottom: 0.6rem;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #4d5554;
  border-width: 1rem 1rem 0;
  margin-left: -0.6rem;
}

.bs-tooltip-bottom {
  margin-top: 0.6rem;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #4d5554;
  border-width: 0 1rem 1rem;
  margin-left: -0.6rem;
}

.bs-tooltip-left {
  margin-right: 0.6rem;
}

.bs-tooltip-left .arrow::before {
  border-left-color: #4d5554;
  border-width: 1rem 0 1rem 1rem;
  margin-top: -0.6rem;
}

.bs-tooltip-right {
  margin-left: 0.6rem;
}

.bs-tooltip-right .arrow::before {
  border-right-color: #4d5554;
  border-width: 1rem 1rem 1rem 0;
  margin-top: -0.6rem;
}

.tooltip-inner {
  background: #4d5554;
  padding: 1rem;
}

.g6-component-contextmenu {
  padding: 0 !important;
}
.g6-component-tooltip {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.janus-font {
  font-family: 'janus';
  font-style: normal;
}

/*
 * Fix for dropdowns inside modals. If the dropdown is placed on body (default),
 * it will be shown z-indexed under the modal.
*/
body.modal-open .dropdown-menu.show {
  z-index: 1060;
}

@import '../../node_modules/@janushealthinc/janus-ui-kit/styles/index.scss';